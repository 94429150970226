import React, { lazy, Suspense, useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import PrivateRoute from "./components/ProtectedRoutes";
import LoginPrivateRoute from "./components/LoginPrivateRoute";
import Loader from "./components/loader/Loader";
import { checkAuth } from "./redux/features/auth/authSlice";
import { getMetaData, getStaticData } from "./redux/features/cms/staticDataSlice";
import { setLanguage } from "./config/Constants";
import { LanguageProvider } from "./components/LanguageProvider";
import "react-toastify/dist/ReactToastify.css";

// Lazy loaded components
const Homepage = lazy(() => import("./pages/Homepage"));
const About_us = lazy(() => import("./pages/About_us"));
const Blogs = lazy(() => import("./pages/Blogs"));
const Contact_us = lazy(() => import("./pages/Contact-us"));
const Gallery = lazy(() => import("./pages/Galleries"));
const Privacy_policy = lazy(() => import("./pages/Privacy_policy"));
const Offers = lazy(() => import("./pages/Offers"));
const Payment_success = lazy(() => import("./pages/Payment_success"));
const Thankyou = lazy(() => import("./pages/Thankyou"));
const Landing_page = lazy(() => import("./pages/Landing_page"));
const ViewBlog = lazy(() => import("./pages/ViewBlog"));
const CareerPage = lazy(() => import('./pages/career/career'));
const JobDetails = lazy(() => import("./pages/career/JobDetails"));
const Terms_conditions = lazy(() => import("./pages/Terms_conditions"));
const Repair_car_quote = lazy(() => import("./pages/Repair_car_quote"));
const Services = lazy(() => import("./pages/Services"));
const Service_view = lazy(() => import("./pages/Service_view"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const Country = lazy(() => import("./pages/Country"));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuth())
      .then((res) => {
        if (res.payload.success) {
          localStorage.clear();
          window.location.reload();
        }
      })
      .catch((error) => {
        // Handle errors if needed
      });
  }, [dispatch]);

  const staticData = useSelector((state) => state.staticData);

  if (staticData.loading === "PENDING") {
    return <Loader />;
  }

  console.clear();

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <ScrollToTop />
        <LanguageProvider>
          <Layout {...staticData.data}>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Country />} />
                <Route path="/home" element={<Homepage />} />
                <Route path="/about-us" element={<About_us />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/contact-us" element={<Contact_us />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/privacy-policy" element={<Privacy_policy />} />
                <Route path="/payment/:status" element={<Payment_success />} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route path="/landing-page/:language/:campaign/:source" element={<Landing_page />} />
                <Route path="/blogs/:id" element={<ViewBlog />} />
                <Route path="/careers" element={<CareerPage />} />
                <Route path="/job-description/:slug" element={<JobDetails />} />
                <Route path="/terms-and-conditions" element={<Terms_conditions />} />
                <Route path="/repair-car-quote" element={<Repair_car_quote />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/:slug" element={<Service_view />} />
                <Route path="page-not-found" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/page-not-found" replace />} />
              </Routes>
            </Suspense>
          </Layout>
        </LanguageProvider>
      </Router>
    </div>
  );
}

export default App;
